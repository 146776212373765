import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Ph4sm4Logo from '../images/logos/logo_hor_blue.png';

type Props = {};

export default function Header({}: Props) {
	const [location, setLocation] = useState<string>(window.location.pathname);
	const [scrollTo, setScrollTo] = useState<string | null>(sessionStorage.getItem('scrollTo'));
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		document.getElementById('overlay--main--modal')?.addEventListener('click', () => {
			handleCloseModal();
		});

		document.addEventListener('scroll', () => {
			document.querySelector('.site--header')?.classList.toggle('sticky', window.scrollY > 0);
		});

		document.querySelectorAll<HTMLLIElement>('.header--list>li')?.forEach((li: HTMLLIElement) => {
			li.addEventListener('click', () => {
				const link: string | null = li.getAttribute('data-link');
				if (!link) return;

				sessionStorage.setItem('scrollTo', link);
				setScrollTo(link);
			});
		});

		document.querySelectorAll<HTMLLIElement>('.modal--list>li')?.forEach((li: HTMLLIElement) => {
			li.addEventListener('click', () => {
				setIsOpen(false);
				document.querySelector('.modal')?.classList.remove('active');
				document.getElementById('overlay--main--modal')?.classList.remove('active');

				const link: string | null = li.getAttribute('data-link');
				if (!link) return;

				sessionStorage.setItem('scrollTo', link);
				setScrollTo(link);
			});
		});
	}, []);

	useEffect(() => {
		setTimeout(() => {
			const scrollToProper: string | null = sessionStorage.getItem('scrollTo') || scrollTo;
			if (!scrollToProper) return;

			const el: HTMLElement | null = document.getElementById(scrollToProper);
			if (!el) return;

			const Y: number = el.offsetTop - 60;

			window.scrollTo({
				top: Y,
				left: 0,
				behavior: 'smooth',
			});

			sessionStorage.removeItem('scrollTo');
			setScrollTo(null);
		}, 20);
	}, [location, scrollTo]);

	useEffect(() => {
		const html = document.querySelector('html');

		if (isOpen) {
			document.querySelector('.barToggler')?.classList.add('open');
			if (html) html.style.overflowY = 'hidden';
		} else {
			document.querySelector('.barToggler')?.classList.remove('open');
			if (html) html.style.overflowY = 'visible';
		}
	}, [isOpen]);

	function handleCloseModal() {
		setIsOpen(false);
		document.querySelector('.modal')?.classList.remove('active');
		document.getElementById('overlay--main--modal')?.classList.remove('active');
		const html = document.querySelector('html');
		if (html) html.style.overflowY = 'visible';
	}

	function toggleBar() {
		setIsOpen((prev) => !prev);
		document.querySelector('.modal')?.classList.toggle('active');
		document.getElementById('overlay--main--modal')?.classList.toggle('active');
	}

	return (
		<>
			<div className="modal flex py-20 items-start justify-center">
				<div className="absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-700" onClick={handleCloseModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>

				<ul className="flex flex-col gap-10 py-10 w-4/5 justify-between modal--list">
					<li data-link="offer">
						<Link to="/">
							<label className="header--label" data-text="Offer">
								Offer
							</label>
						</Link>
					</li>
					<li data-link="projects">
						<Link to="/">
							<label className="header--label" data-text="Projects">
								Projects
							</label>
						</Link>
					</li>
					<li data-link="experience">
						<Link to="/">
							<label className="header--label" data-text="Experience">
								Experience
							</label>
						</Link>
					</li>
					<li data-link="technologies">
						<Link to="/">
							<label className="header--label" data-text="Tech">
								Tech
							</label>
						</Link>
					</li>
					<li data-link="contact">
						<Link to="/">
							<label className="header--label" data-text="Contact">
								Contact
							</label>
						</Link>
					</li>
				</ul>
			</div>
			<div id="overlay--main--modal"></div>
			<header className="site--header">
				<div className="flex items-center w-full justify-between px-20 h-full header--content relative">
					<Link to="/">
						<img
							src={Ph4sm4Logo}
							className="ph4sm4--logo cursor-pointer"
							onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
						/>
					</Link>
					<ul className="flex w-2/3 justify-between header--list items-center">
						<li data-link="offer">
							<Link to="/">
								<label className="header--label" data-text="Offer">
									Offer
								</label>
							</Link>
						</li>
						<li data-link="projects">
							<Link to="/">
								<label className="header--label" data-text="Projects">
									Projects
								</label>
							</Link>
						</li>
						<li data-link="experience">
							<Link to="/">
								<label className="header--label" data-text="Experience">
									Experience
								</label>
							</Link>
						</li>
						<li data-link="technologies">
							<Link to="/">
								<label className="header--label" data-text="Technologies">
									Tech
								</label>
							</Link>
						</li>
						<li data-link="contact">
							<Link to="/">
								<label className="header--label" data-text="Contact">
									Contact
								</label>
							</Link>
						</li>
					</ul>
					<div className="barToggler flex justify-center items-center cursor-pointer" onClick={toggleBar}>
						<div className="barToggler--button"></div>
					</div>
				</div>
			</header>
		</>
	);
}
