import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

type Props = {};

export default function NotFound({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className='flex flex-col w-[90%] lg:w-2/3 gap-16 mx-auto mt-32'>
			<h1 className='text-6xl font-light tracking-[5px] uppercase text-white'>
				Error 404 - Not Found
			</h1>
			<p className='return--home'>
				<Link to='/'>Return home</Link>
			</p>
		</div>
	);
}
