import ReactGA from 'react-ga4';

export function GAEvent(category: string, action: string, label?: string, value?: number,
    nonInteraction?: boolean) {
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        value: value, // optional, must be a number
        nonInteraction: nonInteraction, // optional, true/false
        //transport: transport, // optional, beacon/xhr/image
    });
}