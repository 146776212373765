import React from 'react';
import GithubIcon from '../images/logos/github-small.png';
import LinkedInIcon from '../images/logos/linkedin.png';
import { GAEvent } from '../globaluse';

type Props = {};

const MailIcon = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-8 h-8">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
			/>
		</svg>
	);
};

const OuterLink = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#a1ceef" className="w-4 h-4">
			<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
		</svg>
	);
};

const Phone = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
			/>
		</svg>
	);
};

export default function ContactSection({}: Props) {
	return (
		<section id="contact">
			<div className="contact--section">
				<h1 className="section--title">Contact</h1>
				<div className="section--content flex gap-80 items-center">
					<div className="flex flex-col gap-10">
						<div className="contact--info" data-aos="zoom-in-down" data-aos-duration="1000" data-aos-once="true">
							<MailIcon />

							<a href="mailto:ph4sm4solutions@gmail.com">ph4sm4solutions@gmail.com</a>
						</div>
						<div className="contact--info" data-aos="zoom-in-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
							<img src={GithubIcon} />

							<a
								href="https://github.com/Ph4sm4"
								target="_blank"
								className="anim--link"
								onClick={() => GAEvent('Contact section', 'CONTACT Github link', 'Github link clicked')}>
								Ph4sm4
								<OuterLink />
							</a>
						</div>
						<div className="contact--info" data-aos="zoom-in-down" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true">
							<img src={LinkedInIcon} className="w-6 h-6" />

							<a href="https://www.linkedin.com/in/olaf-dalach/" target="_blank" className="anim--link">
								Olaf Dalach
								<OuterLink />
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
