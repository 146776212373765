import React from 'react';

type Props = {};

export default function OfferSection({}: Props) {
	return (
		<section id='offer'>
			<div className='offer--section'>
				<h1 className='section--title'>What do I do?</h1>
				<div className='section--content flex gap-80 items-center'>
					<ul className='styled--grid wider'>
						<h2
							data-aos='fade-down'
							data-aos-duration='1000'
							id='offer--anchor'
							data-aos-once='true'>
							Websites
						</h2>
						<h2
							data-aos='fade-down'
							data-aos-duration='1000'
							data-aos-delay='200'
							data-aos-anchor='#offer--anchor'
							data-aos-once='true'>
							Scripts
						</h2>
						<h2
							data-aos='fade-down'
							data-aos-duration='1000'
							data-aos-delay='300'
							data-aos-anchor='#offer--anchor'
							data-aos-once='true'>
							Discord Bots
						</h2>
						<h2
							data-aos='fade-down'
							data-aos-duration='1000'
							data-aos-delay='400'
							data-aos-anchor='#offer--anchor'
							data-aos-once='true'>
							Desktop Apps
						</h2>
					</ul>
				</div>
			</div>
		</section>
	);
}
