import React, { useState, useEffect } from 'react';
import ContactSection from './ContactSection';
import ExperienceSection from './ExperienceSection';
import Header from './Header';
import IntroSection from './IntroSection';
import MyselfSection from './MyselfSection';
import ProjectsSection from './ProjectsSection';
import TechnologiesSection from './TechnologiesSection';
import Footer from './Footer';
import OfferSection from './OfferSection';
import { Project } from '../App';

type Props = {};

export default function MainPage({}: Props) {
  return (
    <>
      <main>
        <IntroSection />
        <OfferSection />
        <ProjectsSection />
        <ExperienceSection />
        <TechnologiesSection />
        <ContactSection />
      </main>
      <Footer color='#101010' />
    </>
  );
}
