type Props = {};

export default function TechnologiesSection({}: Props) {
	return (
		<section id="technologies">
			<div className="technologies--section">
				<h1 className="section--title">Technologies</h1>
				<div className="section--content styled--grid">
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100" id="technology--anchor" data-aos-once="true">
						Next.js
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Node.js
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200" data-aos-anchor="#technology--anchor" data-aos-once="true">
						WebSockets
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" data-aos-anchor="#technology--anchor" data-aos-once="true">
						MongoDB
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Firebase
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300" data-aos-anchor="#technology--anchor" data-aos-once="true">
						C++ & C
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="400" data-aos-anchor="#technology--anchor" data-aos-once="true">
						React.js
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="400" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Qt C++
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="400" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Typescript
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500" data-aos-anchor="#technology--anchor" data-aos-once="true">
						.NET
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Blazor
					</h2>

					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="600" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Tailwind CSS
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="600" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Git
					</h2>
					<h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="700" data-aos-anchor="#technology--anchor" data-aos-once="true">
						Lektor CMS
					</h2>
				</div>
			</div>
		</section>
	);
}
