import React, { useEffect, useState } from 'react';

type Props = {
	color: string;
};

export default function Footer({ color }: Props) {
	const [year, setYear] = useState<number | null>();
	useEffect(() => {
		setYear(new Date().getUTCFullYear());
	}, []);

	return (
		<footer>
			<div className={`h-[200px] w-full text-white tracking-wide font-light text-md uppercase flex py-5`} style={{ backgroundColor: color }}>
				<div className="mt-auto mx-auto footer--copy w-[50%] text-center">&copy; {year} Ph4sm4 Solutions | Olaf Dalach</div>
			</div>
		</footer>
	);
}
