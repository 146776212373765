import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

type Props = {};

const OuterLink = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#a1ceef" className="w-4 h-4">
			<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
		</svg>
	);
};

export default function ExperienceSection({}: Props) {
	function formatDateDifference(startDate: Dayjs, endDate: Dayjs): string {
		const years: number = endDate.diff(startDate, 'year');
		const months: number = endDate.diff(startDate.add(years, 'year'), 'month') + 1;
		const days: number = endDate.diff(startDate.add(years, 'year').add(months, 'month'), 'day');

		const pluralize = (value: number, unit: string): string => (value === 1 ? `${value} ${unit}` : `${value} ${unit}s`);

		const parts: string[] = [];
		if (years > 0) parts.push(pluralize(years, 'year'));
		if (months > 0) parts.push(pluralize(months, 'month'));
		if (days > 0) parts.push(pluralize(days, 'day'));

		return parts.join(' ');
	}

	return (
		<section id="experience">
			<div className="experience--section">
				<h1 className="section--title">Experience</h1>
				<div className="section--content flex flex-col gap-24 experience--content">
					<div className="category--no--links category" data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
						<h2>Employment History</h2>
						<ul className="experience--list">
							<li>
								<a href="https://www.knf.gov.pl/" className="anim--link" target="_blank">
									<h3>
										The Polish Financial Supervision Authority (UKNF)
										<OuterLink />
									</h3>
								</a>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.09.2023 - now <small> ({formatDateDifference(dayjs('2023-09-01'), dayjs())})</small>
										</i>
									</p>
									<p>
										<strong>Role:</strong> <i>Full-Stack Developer</i>
									</p>
									<p>
										<strong>Employment type:</strong> <i>3/4 FTE</i>
									</p>
								</div>
							</li>
							<li>
								<a href="https://softwise.ai/" className="anim--link" target="_blank">
									<h3>
										Softwise.ai
										<OuterLink />
									</h3>
								</a>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.09.2024 - now <small> ({formatDateDifference(dayjs('2024-09-01'), dayjs())})</small>
										</i>
									</p>
									<p>
										<strong>Role:</strong> <i>Front-End Developer</i>
									</p>
									<p>
										<strong>Employment type:</strong> <i>Contract</i>
									</p>
								</div>
							</li>
							<li>
								<a href="https://www.nask.pl/" className="anim--link" target="_blank">
									<h3>
										The Scientific and Academic Computer Network (NASK)
										<OuterLink />
									</h3>
								</a>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.09.2024 - now <small> ({formatDateDifference(dayjs('2024-09-01'), dayjs())})</small>
										</i>
									</p>
									<p>
										<strong>Role:</strong> <i>Full-Stack Developer</i>
									</p>
									<p>
										<strong>Employment type:</strong> <i>Contract</i>
									</p>
								</div>
							</li>
							<li>
								<p>
									<h3>Ph4sm4 Solutions</h3>
								</p>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.12.2022 - now <small> ({formatDateDifference(dayjs('2022-12-01'), dayjs())})</small>
										</i>
									</p>
									<p>
										<strong>Role:</strong> <i>Full-Stack Developer</i>
									</p>
									<p>
										<strong>Employment type:</strong> <i>Self-employed | Freelance</i>
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div className="category--no--links category" data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
						<h2>Internships</h2>
						<ul className="experience--list">
							<li>
								<a href="https://www.knf.gov.pl/" className="anim--link" target="_blank">
									<h3>
										The Polish Financial Supervision Authority (UKNF)
										<OuterLink />
									</h3>
								</a>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.06.2023 - 01.09.2023 <small> ({formatDateDifference(dayjs('2023-06-01'), dayjs('2023-09-01'))})</small>
										</i>
									</p>
									<p>
										Throughout this internship my main objective was to develop full-stack applications used within internal systems. I
										explored various aspects of development utilizing C#, .NET, Entity Framework, BLAZOR, Next.js and many other tools. It
										was a valuable experience which further improved my analytic thinking.
									</p>
								</div>
							</li>
							<li>
								<a href="https://www.wecamo.io/" className="anim--link" target="_blank">
									<h3>
										WECAMO
										<OuterLink />
									</h3>
								</a>
								<div className="flex flex-col gap-1">
									<p>
										<strong>Time span:</strong>{' '}
										<i>
											01.07.2022 - 01.10.2022 <small> ({formatDateDifference(dayjs('2022-07-01'), dayjs('2022-10-01'))})</small>
										</i>
									</p>
									<p>
										During this period I was responsible for writing software testing scripts, fixing bugs in company's projects and have
										successfully developed websites using React + Typescript and Lektor CMS. <br />I have gained experience in regard to
										learning required tools to complete a task, how daily meetings function and what it generally means to be a full-time
										programmer.
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div className="category" data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
						<h2>Courses</h2>
						<div className="category--links courses--list">
							<a href="https://indekswkieszeni.pl/olimpiada-informatyczna/" className="anim--link" target="_blank">
								Algorithmics, Indeks w Kieszeni
								<OuterLink />
							</a>
							<a href="https://scrimba.com/learn/learnreact" className="anim--link" target="_blank">
								Learn React, Scrimba
								<OuterLink />
							</a>
							<a href="https://www.udemy.com/course/unrealcourse/" className="anim--link" target="_blank">
								Unreal Engine 5, Udemy
								<OuterLink />
							</a>
						</div>
					</div>
					<div className="category--no--links category" data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
						<h2>What I've done/used</h2>
						<ul>
							<li>Small games in Unreal Engine C++</li>
							<li>Desktop apps and visualizations in Qt C++</li>
							<li>Tailwind CSS usage throughout my web projects</li>
							<li>Firebase, Firestore and Firebase OAuth</li>
							<li>AWS, Google Analytics and Cloudflare</li>
							<li>Scripts and applications with API</li>
							<li>Lots of algorithmic type problems solved (Binary Search, DFS, BFS, Dijkstra, Interval tree's algorithms)</li>
							<li>Advanced knowledge of the C++ STL library and sorting algorithms</li>
							<li>Custom API's (Express.js)</li>
							<li>Various webscrapers (selenium.js, cypress.js)</li>
							<li>Lektor CMS</li>
							<li>Software testing</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}
