import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Project } from '../App';
import { GAEvent } from '../globaluse';

type Props = {};

const Info = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#a1ceef" className="w-6 h-6">
			<path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
		</svg>
	);
};

export default function ProjectsSection({}: Props) {
	return (
		<section id="projects">
			<div className="projects--section">
				<h1 className="section--title">Works</h1>
				<div className="section--content projects--grid grid grid-cols-2 grid-flow-row gap-10">
					<div className="category" data-aos="fade-down" data-aos-duration="1000" id="qt" data-aos-once="true">
						<h2>Qt C++</h2>
						<div className="category--links">
							<Link to="Finelog-App" className="contents">
								Finelog App <Info />
							</Link>

							<Link to="AutoClicker" className="contents">
								AutoClicker
								<Info />
							</Link>
							<Link to="Perlin-Noise-Visual-Representation" className="contents">
								Perlin Noise Visual Representation (1D and 2D)
								<Info />
							</Link>

							<Link to="AStar-Visual-Representation" className="contents">
								A* Visual Representation
								<Info />
							</Link>
							<Link to="Weatheron" className="contents">
								Weatheron
								<Info />
							</Link>
							<Link to="Calculator-Ip" className="contents">
								Calculator IP <Info />
							</Link>
						</div>
					</div>
					<div className="category" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="250" data-aos-once="true">
						<h2>React.js</h2>
						<div className="category--links">
							<a
								href="https://oceancapital.pl/"
								target="_blank"
								onClick={() => GAEvent('Projects section', 'Ocean capital website', 'Ocean capital website link clicked')}>
								OCEAN CAPITAL website <Info />
							</a>
							<a
								href="https://finelog.de/"
								target="_blank"
								onClick={() => GAEvent('Projects section', 'Finelog website', 'Finelog website link clicked')}>
								Finelog website
								<Info />
							</a>

							<Link to="Google-Forms-Copy" className="contents">
								Google Forms Copy (not finished yet)
								<Info />
							</Link>

							<a
								href="https://kancelariamlm.pl/"
								target="_blank"
								onClick={() => GAEvent('Projects section', 'Kancelaria MLM website', 'Kancelaria MLM website link clicked')}>
								Kancelaria MLM <Info />
							</a>

							<a
								href="https://paneco.eu/map/"
								target="_blank"
								onClick={() => GAEvent('Projects section', 'Paneco map website', 'Paneco map website link clicked')}>
								Paneco map of Europe
								<Info />
							</a>
						</div>
					</div>
					<div
						className="category"
						data-aos="fade-down"
						data-aos-duration="1000"
						data-aos-delay="500"
						data-aos-offset="-100"
						data-aos-once="true">
						<h2>Node.js</h2>
						<ul className="category--links">
							<Link to="Crypto-Transactions-Visualizer" className="contents">
								Crypto Transactions Visualizer
								<Info />
							</Link>
						</ul>
						<ul className="category--links">
							<Link to="Discord-Bot" className="contents">
								Discord bot (over 45 commands)
								<Info />
							</Link>
						</ul>
					</div>
					<div
						className="category"
						data-aos="fade-down"
						data-aos-duration="1000"
						data-aos-delay="750"
						data-aos-offset="-100"
						data-aos-once="true">
						<h2>Unreal Engine</h2>
						<ul className="category--links">
							<Link to="Ultimate-Shooter" className="contents">
								Ultimate Shooter (Udemy project)
								<Info />
							</Link>
							<Link to="Exotic-Island" className="contents">
								Exotic Island
								<Info />
							</Link>
						</ul>
					</div>
					<div
						className="category"
						data-aos="fade-down"
						data-aos-duration="1000"
						data-aos-delay="750"
						data-aos-offset="-100"
						data-aos-once="true">
						<h2>C</h2>
						<ul className="category--links">
							<Link to="Project-Penguins" className="contents">
								"Hey, that's my fish"
								<Info />
							</Link>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}
